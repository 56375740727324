export const Devices_TYPE_REQUEST = "Devices_TYPE_REQUEST";
export const Devices_TYPE_SUCCESS = "Devices_TYPE_SUCCESS";
export const Devices_TYPE_FAILURE = "Devices_TYPE_FAILURE";

export const DEVICES_DDL_REQUEST = "DEVICES_DDL_REQUEST";
export const DEVICES_DDL_SUCCESS = "DEVICES_DDL_SUCCESS";
export const DEVICES_DDL_FAILURE = "DEVICES_DDL_FAILURE";
export const DEVICES_DDL_RESET = "DEVICES_DDL_RESET";

export const Devices_ADD_REQUEST = "Devices_ADD_REQUEST";
export const Devices_ADD_SUCCESS = "Devices_ADD_SUCCESS";
export const Devices_ADD_FAILURE = "Devices_ADD_FAILURE";

export const Devices_DELETE_REQUEST = "Devices_DELETE_REQUEST";
export const Devices_DELETE_SUCCESS = "Devices_DELETE_SUCCESS";
export const Devices_DELETE_FAILURE = "Devices_DELETE_FAILURE";

export const Devices_UPDATE_REQUEST = "Devices_UPDATE_REQUEST";
export const Devices_UPDATE_SUCCESS = "Devices_UPDATE_SUCCESS";
export const Devices_UPDATE_FAILURE = "Devices_UPDATE_FAILURE";
export const Devices_UPDATE_RESET = "Devices_UPDATE_RESET";

export const Devices_Import_REQUEST = "Devices_Import_REQUEST";
export const Devices_Import_SUCCESS = "Devices_Import_SUCCESS";
export const Devices_Import_FAILURE = "Devices_Import_FAILURE";

export const Devices_Export_REQUEST = "Devices_Export_REQUEST";
export const Devices_Export_SUCCESS = "Devices_Export_SUCCESS";
export const Devices_Export_FAILURE = "Devices_Export_FAILURE";

export const Devices_DETAILS_REQUEST = "Devices_DETAILS_REQUEST";
export const Devices_DETAILS_SUCCESS = "Devices_DETAILS_SUCCESS";
export const Devices_DETAILS_FAILURE = "Devices_DETAILS_FAILURE";
export const Devices_DETAILS_RESET = "Devices_DETAILS_RESET";

export const DEVICE_MODULE_REQUEST = "Devices_Module_REQUEST";
export const DEVICE_MODULE_SUCCESS = "Devices_Module_SUCCESS";
export const DEVICE_MODULE_FAILURE = "Devices_Module_FAILURE";

